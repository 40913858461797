import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { environment } from './environments/environment';

// add Google Maps JS SDK to head
const script = document.createElement('script');
script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsKey}&libraries=drawing,geometry&callback=Function.prototype`;
script.defer = true;
script.async = true;
document.head.appendChild(script);
// ----------------------------------

if (environment.tawktoPropertyId) {
  // add tawk.to widget to head
  const tawktoScript = document.createElement('script');
  tawktoScript.text = `
  var Tawk_API = Tawk_API || {},
    Tawk_LoadStart = new Date();
  (function () {
    var s1 = document.createElement('script'),
      s0 = document.getElementsByTagName('script')[0];
    s1.async = true;
    s1.src = 'https://embed.tawk.to/${environment.tawktoPropertyId}/${environment.tawktoWidgetId}';
    s1.charset = 'UTF-8';
    s1.setAttribute('crossorigin', '*');
    s0.parentNode.insertBefore(s1, s0);
  })();
`;
  document.body.appendChild(tawktoScript);
}

bootstrapApplication(AppComponent, appConfig);
