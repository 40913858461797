import { Field, FieldType, FieldsGroup, Form, LocationConfig, OptionConfig, ScaleConfig } from '@dataformz/models';
//@ts-ignore
import * as shortid from 'shortid';
import { omitProperties } from './object-util';

export const getDefaultField = (type = FieldType.text): Field => {
  const field: Field = {
    uid: shortid.generate(),
    key: '',
    type: type,
    label: '',
    description: '',
    required: false,
    showDescription: false,
    hasVisibilityRules: false,
    hidden: false,
  };

  switch (type) {
    case FieldType.dropdown:
    case FieldType.choice:
    case FieldType.multipleChoice:
      field.config = { manual: true, options: [{ name: 'Option 1', code: null }] } as OptionConfig;
      break;
    case FieldType.rating:
      field.config = { level: 5 } as ScaleConfig;
      break;
    case FieldType.scale:
      field.config = { level: 10 } as ScaleConfig;
      break;

    case FieldType.location:
      field.config = { mode: 'manual' } as LocationConfig;
      break;

    default:
      break;
  }
  return field;
};

export const groupFields = (form: Form | undefined): FieldsGroup[] => {
  const fieldsGroups: FieldsGroup[] = [];

  form?.fields?.forEach((field) => {
    if (field.type === 'section') {
      fieldsGroups.push({
        title: field.label,
        description: field.description,
        fields: [],
      });
    } else {
      if (fieldsGroups.length === 0) {
        fieldsGroups.push({
          title: '',
          description: '',
          fields: [],
        });
      }
      //push field to last group
      fieldsGroups[fieldsGroups.length - 1].fields?.push(field);
    }
  });
  return fieldsGroups;
};

export const convertOldForm = (form: Form | undefined): Form => {
  const fields: Field[] = [];
  //@ts-expect-error
  if (form?.fieldsGroups?.length > 0) {
    //@ts-expect-error
    form.fieldsGroups?.forEach((fg: FieldsGroup, index: number) => {
      if (index > 0) {
        fields.push({
          uid: shortid.generate(),
          label: fg.title,
          description: fg.description,
          showDescription: !!fg.description,
          type: FieldType.section,
          key: '',
        });
      }
      fg?.fields?.forEach((field: Field) => {
        fields.push(field);
      });
    });
    //@ts-expect-error
    form = { ...form, fields: fields };
  }
  // @ts-expect-error fieldsGroups doesn't exist but might be present in old forms
  return omitProperties(form, 'fieldsGroups') as Form;
};
