import { sharedEnvironment } from '@dataformz/environments';

export const environment = {
  env: 'production',
  version: sharedEnvironment.version,
  production: true,
  firebase: {
    apiKey: 'AIzaSyBjoM-tMwDDtmJZGLmnAeQPwoJnJq5J__c',
    authDomain: 'dataformz-prod.firebaseapp.com',
    databaseURL: 'https://dataformz-prod.firebaseio.com',
    projectId: 'dataformz-prod',
    storageBucket: 'dataformz-prod.appspot.com',
    messagingSenderId: '343509636729',
    appId: '1:343509636729:web:24a95dd97d02d81c11a6a8',
    measurementId: 'G-JLN3EHTWV6',
  },
  sentry: 'https://d160cc72fd9747a7b93d55abccec2818@o153778.ingest.us.sentry.io/1242955',
  googleMapsKey: sharedEnvironment.prod.googleMapsKey,
  collectBaseUrl: 'https://collect.dataformz.com',
  auth0: sharedEnvironment.prod.auth0,
  kibanaUrl: 'https://analytics.dataformz.com',
  plans: [
    {
      name: 'Standard',
      pricing: {
        monthly: {
          name: 'Monthly',
          price: 49,
          stripePriceId: 'price_1H4DwILZSy08ijHPjkXP2dgV',
        },
        yearly: {
          name: 'Yearly',
          price: 490,
          stripePriceId: 'price_1H4DwILZSy08ijHPyiLia835',
        },
      },
      description: 'apps.manage.settings.billing.standardPlanDescription',
    },
  ],
  stripeKey: 'pk_live_jv6oFbJwM2UqjamESHOnE2xr001rjVEJGg',
  feature: {
    analytics: false,
  },
  tawktoPropertyId: sharedEnvironment.tawktoPropertyId,
  tawktoWidgetId: sharedEnvironment.tawktoWidgetId,
};
