import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AuthService as Auth0Service } from '@auth0/auth0-angular';
import { combineLatest, first, firstValueFrom, switchMap } from 'rxjs';
import { AuthService } from './auth.service';

export function initializeAppFactory(appInitService: AppInitService) {
  return () => appInitService.Init();
}

@Injectable({ providedIn: 'root' })
export class AppInitService {
  constructor(private afAuth: AngularFireAuth, private authService: AuthService, private auth0Service: Auth0Service) {}

  Init() {
    return combineLatest([this.afAuth.authState, this.auth0Service.isAuthenticated$]).pipe(
      //complete the observable after the first value is emitted
      first(),
      switchMap(async ([firebaseUser, isAuth0Authenticated]) => {
        if (isAuth0Authenticated && firebaseUser === null) {
          try {
            const user = await firstValueFrom(this.auth0Service.user$);
            await this.authService.loginToFirebase(user!);
            // Refresh user profile info on every login
            const { sub, picture, email, family_name, given_name } = user!;
            const fullName = `${given_name} ${family_name}`;

            //todo: a user was set instead of being update. Might want to double check the upsert function
            this.authService.createUserifNotExists(sub!, {
              fullName: fullName,
              pictureUrl: picture,
              email: email,
              firstName: given_name,
              lastName: family_name,
              type: 'user',
            });
          } catch (err) {
            console.error(err);
            this.authService.logout();
          }
        }
      })
    );
  }
}
