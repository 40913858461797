import { FieldType } from './fieldType.interface';

export interface FieldtypeProperty {
  type: string;
  icon?: string;
  svgIcon?: string;
  showRuleset: boolean;
  operators?: string[];
  showInFormLink: boolean;
  showInVisibilityRules: boolean;
  web: boolean;
  isEditable: boolean;
}
// ['eq','neq','gt','lt','gte','lte','contains', 'notContains','empty','notEmpty', 'startsWith',  'endsWith']
export const FieldTypeProperties: { [type: string]: FieldtypeProperty } = {
  [FieldType.section]: {
    type: FieldType.section,
    icon: 'splitscreen',
    showRuleset: false,
    showInVisibilityRules: false,
    showInFormLink: false,
    web: true,
    isEditable: false,
  },
  [FieldType.text]: {
    type: FieldType.text,
    icon: 'short_text',
    showRuleset: true,
    showInVisibilityRules: true,
    operators: ['eq', 'neq', 'contains', 'empty', 'notEmpty', 'startsWith', 'endsWith'],
    showInFormLink: true,
    web: true,
    isEditable: true,
  },
  [FieldType.number]: {
    type: FieldType.number,
    icon: 'looks_one',
    showRuleset: true,
    showInVisibilityRules: true,
    operators: ['eq', 'neq', 'gt', 'lt', 'gte', 'lte', 'empty', 'notEmpty'],
    showInFormLink: false,
    web: true,
    isEditable: true,
  },
  [FieldType.date]: {
    type: FieldType.date,
    icon: 'date_range',
    showRuleset: true,
    showInVisibilityRules: true,
    operators: ['eq', 'neq', 'gt', 'lt', 'gte', 'lte', 'empty', 'notEmpty'],
    showInFormLink: false,
    web: true,
    isEditable: true,
  },
  [FieldType.paragraph]: {
    type: FieldType.paragraph,
    icon: 'subject',
    showRuleset: true,
    showInVisibilityRules: false,
    operators: ['eq', 'neq', 'contains', 'empty', 'notEmpty', 'startsWith', 'endsWith'],
    showInFormLink: false,
    web: true,
    isEditable: true,
  },
  [FieldType.dropdown]: {
    type: FieldType.dropdown,
    icon: 'arrow_drop_down_circle',
    showRuleset: true,
    showInVisibilityRules: true,
    operators: ['eq', 'neq', 'empty', 'notEmpty'],
    showInFormLink: true,
    web: true,
    isEditable: true,
  },
  [FieldType.yesNo]: {
    type: FieldType.choice,
    icon: 'flaky',
    showRuleset: true,
    showInVisibilityRules: true,
    operators: ['eq', 'neq'],
    showInFormLink: true,
    web: true,
    isEditable: true,
  },
  [FieldType.choice]: {
    type: FieldType.choice,
    icon: 'radio_button_checked',
    showRuleset: true,
    showInVisibilityRules: true,
    operators: ['eq', 'neq', 'empty', 'notEmpty'],
    showInFormLink: true,
    web: true,
    isEditable: true,
  },
  [FieldType.fileUpload]: {
    type: FieldType.fileUpload,
    icon: 'cloud_upload',
    showRuleset: true,
    showInVisibilityRules: false,
    showInFormLink: false,
    web: true,
    isEditable: false,
  },
  [FieldType.time]: {
    type: FieldType.time,
    icon: 'access_time',
    showRuleset: true,
    showInVisibilityRules: true,
    operators: ['eq', 'neq', 'gt', 'lt', 'gte', 'lte', 'empty', 'notEmpty'],
    showInFormLink: false,
    web: true,
    isEditable: true,
  },
  [FieldType.multipleChoice]: {
    type: FieldType.multipleChoice,
    icon: 'check_box',
    showRuleset: true,
    showInVisibilityRules: true,
    operators: ['empty', 'notEmpty', 'contains', 'notContains'],
    showInFormLink: false,
    web: true,
    isEditable: true,
  },
  [FieldType.image]: {
    type: FieldType.image,
    icon: 'photo',
    showRuleset: true,
    showInVisibilityRules: false,
    showInFormLink: false,
    web: true,
    isEditable: false,
  },
  [FieldType.scale]: {
    type: FieldType.scale,
    icon: 'linear_scale',
    showRuleset: true,
    showInVisibilityRules: true,
    operators: ['eq', 'neq', 'gt', 'lt', 'gte', 'lte', 'empty', 'notEmpty'],
    showInFormLink: false,
    web: true,
    isEditable: true,
  },
  [FieldType.rating]: {
    type: FieldType.rating,
    icon: 'star',
    showRuleset: true,
    showInVisibilityRules: true,
    operators: ['eq', 'neq', 'gt', 'lt', 'gte', 'lte', 'empty', 'notEmpty'],
    showInFormLink: false,
    web: true,
    isEditable: true,
  },
  [FieldType.signature]: {
    type: FieldType.signature,
    icon: 'edit',
    showRuleset: true,
    showInVisibilityRules: false,
    showInFormLink: false,
    web: false,
    isEditable: false,
  },
  [FieldType.formLink]: {
    type: FieldType.formLink,
    icon: 'list_alt',
    showRuleset: true,
    showInVisibilityRules: false,
    operators: ['eq', 'neq', 'empty', 'notEmpty'],
    showInFormLink: false,
    web: true,
    isEditable: true,
  },
  [FieldType.area]: {
    type: FieldType.area,
    icon: '',
    svgIcon: 'area_map',
    showRuleset: true,
    showInVisibilityRules: false,
    showInFormLink: false,
    web: true,
    isEditable: false,
  },
  [FieldType.distance]: {
    type: FieldType.distance,
    icon: '',
    svgIcon: 'distance',
    showRuleset: true,
    showInVisibilityRules: false,
    showInFormLink: false,
    web: true,
    isEditable: false,
  },
  [FieldType.audio]: {
    type: FieldType.audio,
    icon: 'mic',
    showRuleset: true,
    showInVisibilityRules: false,
    showInFormLink: false,
    web: true,
    isEditable: false,
  },
  [FieldType.video]: {
    type: FieldType.video,
    icon: 'videocam',
    showRuleset: true,
    showInVisibilityRules: false,
    showInFormLink: false,
    web: true,
    isEditable: false,
  },
  [FieldType.location]: {
    type: FieldType.location,
    icon: 'location_on',
    showRuleset: true,
    showInVisibilityRules: false,
    showInFormLink: false,
    web: true,
    isEditable: false,
  },
  [FieldType.imageGeoTag]: {
    type: FieldType.imageGeoTag,
    icon: 'satellite',
    showRuleset: true,
    showInVisibilityRules: false,
    showInFormLink: false,
    web: true,
    isEditable: false,
  },
  [FieldType.barcode]: {
    type: FieldType.barcode,
    icon: 'qr_code',
    showRuleset: true,
    showInVisibilityRules: false,
    showInFormLink: false,
    web: false,
    isEditable: false,
  },
};
