export enum FieldType {
  text = 'text',
  number = 'number',
  paragraph = 'paragraph',
  date = 'date',
  dropdown = 'dropdown',
  choice = 'choice',
  multipleChoice = 'multipleChoice',
  fileUpload = 'fileUpload',
  time = 'time',
  image = 'image',
  rating = 'rating',
  scale = 'scale',
  signature = 'signature',
  formLink = 'formLink',
  area = 'area',
  distance = 'distance',
  audio = 'audio',
  video = 'video',
  location = 'location',
  imageGeoTag = 'imageGeoTag',
  barcode = 'barcode',
  section = 'section',
  yesNo = 'yesNo',
}
