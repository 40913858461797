import { CollectionNames, JobCategoryId, JobQueueName } from '@dataformz/models';

// Projects
export const projectsCollectionPath = () => `${CollectionNames.projects}`;
export const projectDocumentPath = (projectId: string) => `${projectsCollectionPath()}/${projectId}`;

// Project aggregations
export const projectsAggregationsCollectionPath = () => `${CollectionNames.ProjectsAggregations}`;
export const projectAggregationDocumentPath = (projectId: string) =>
  `${projectsAggregationsCollectionPath()}/${projectId}`;

// Forms
export const formsCollectionPath = (projectId: string) => `${projectDocumentPath(projectId)}/${CollectionNames.forms}`;
export const formDocumentPath = (projectId: string, formId: string) => `${formsCollectionPath(projectId)}/${formId}`;

// Forms
export const globalChoiceListCollectionPath = (projectId: string) =>
  `${projectDocumentPath(projectId)}/${CollectionNames.globalChoiceLists}`;
export const globalChoiceListDocumentPath = (projectId: string, formId: string) =>
  `${globalChoiceListCollectionPath(projectId)}/${formId}`;

// Forms aggregations
export const formsAggregationsCollectionPath = (projectId: string) =>
  `${projectDocumentPath(projectId)}/${CollectionNames.FormsAggregations}`;
export const formsAggregationsDocumentPath = (projectId: string, formId: string) =>
  `${formsAggregationsCollectionPath(projectId)}/${formId}`;

// Published Forms
export const formVersionsColectionPath = (projectId: string, formId: string) =>
  `${formDocumentPath(projectId, formId)}/${CollectionNames.versions}`;
export const formVersionDocumentPath = (projectId: string, formId: string, version: number) =>
  `${formVersionsColectionPath(projectId, formId)}/${version}`;

// Responses
export const responsesCollectionPath = (projectId: string, formId: string) =>
  `${formDocumentPath(projectId, formId)}/${CollectionNames.responses}`;
export const responseDocumentPath = (projectId: string, formId: string, responseId: string) =>
  `${responsesCollectionPath(projectId, formId)}/${responseId}`;

// Forms version
export const versionsCollectionPath = (projectId: string, formId: string) =>
  `${formDocumentPath(projectId, formId)}/${CollectionNames.versions}`;

// Users aggregations
export const usersAggregationsCollectionPath = () => `${CollectionNames.UsersAggregations}`;
export const userAggregationDocumentPath = (userId: string) => `${usersAggregationsCollectionPath()}/${userId}`;

// System jobs
export const systemJobsCollectionPath = (JobCategoryId: JobCategoryId, jobName: JobQueueName) =>
  `${CollectionNames.jobs}/${JobCategoryId}/${jobName}`;

export const systemJobDocumentPath = (JobCategoryId: JobCategoryId, jobName: JobQueueName, id: string) =>
  `${systemJobsCollectionPath(JobCategoryId, jobName)}/${id}`;

// Project Jobs
export const projectJobsCollectionPath = (projectId: string, JobCategoryId: JobCategoryId, jobName: JobQueueName) =>
  `${projectDocumentPath(projectId)}/${CollectionNames.jobs}/${JobCategoryId}/${jobName}`;

export const projectJobDocumentPath = (
  projectId: string,
  JobCategoryId: JobCategoryId,
  jobName: JobQueueName,
  id: string
) => `${projectJobsCollectionPath(projectId, JobCategoryId, jobName)}/${id}`;

// export const sendEmailJobsCollectionPath = (projectId: string) =>
//   `${projectDocumentPath(projectId)}/${CollectionNames.jobs}/${JobCategoryId.email}/${CollectionNames.sendEmailJobs}`;

// export const sendEmailJobDocumentPath = (projectId: string, id: string) =>
//   `${sendEmailJobsCollectionPath(projectId)}/${id}`;
