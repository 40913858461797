import { DateValue, FieldOption, MediaValue, TimeValue } from '@dataformz/models';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);

export const formatChoiceValue = (value: FieldOption): string => {
  const optionValue = value?.code;
  const optionName = value?.name;

  return optionName || optionValue || '';
};

export function formatMultipleChoiceValue(values: FieldOption[] | undefined) {
  if (Array.isArray(values)) {
    return values?.map((value) => formatChoiceValue(value)).join(', ');
  } else {
    return '';
  }
}

export function compareFieldOption(option1: FieldOption, option2: FieldOption): boolean {
  if (!option2) return false;

  if (option1?.code && option2?.code) {
    return option1.code === option2.code;
  } else {
    return option1?.name === option2?.name;
  }
}

export function formatDateValue(value: DateValue) {
  if (value && Array.isArray(value)) {
    const [year, month, day] = value;
    const date = new Date(year, month, day);
    return dayjs(date).format('ll');
  } else {
    return '';
  }
}

export function formatTimeValue(value: TimeValue) {
  if (value && Array.isArray(value)) {
    const [hour, minute] = value;
    const date = new Date(0, 0, 0, hour, minute);
    return dayjs(date).format('LT');
  } else return '';
}

export const convertDateValueToDate = (dateValue: DateValue) => {
  return new Date(dateValue[0], dateValue[1], dateValue[2], 0, 0, 0, 0);
};

export const convertTimeValueToTime = (timeValue: TimeValue) => {
  return new Date(0, 0, 0, timeValue[0], timeValue[1]);
};

export const isFieldEmpty = (value: unknown): boolean => {
  if (value == null) {
    return true;
  } else if (typeof value === 'object') {
    return Object.keys(value).length === 0;
  } else if (typeof value === 'string') {
    return value.length === 0;
  } else if (Array.isArray(value)) {
    return value.length === 0;
  } else {
    return false;
  }
};

export const formatArea = (area: number | undefined): string => {
  if (area === undefined) {
    return '';
  }
  // Convert area to a number if it's a string
  area = Number(area);

  // Check if area is a valid number
  if (isNaN(area)) {
    return 'Invalid area';
  }

  // Convert to square kilometers if area is larger than 1000000 (1 km²)
  if (area >= 1000000) {
    const kmArea = (area / 1000000).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return kmArea + ' km²';
  }

  // Format the area in square meters
  const mArea = area.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return mArea + ' m²';
};

export const formatLatLng = (latLng: number | undefined): string => {
  if (latLng === undefined) {
    return '';
  }

  // Convert latLng to a number if it's a string
  latLng = Number(latLng);

  // Check if latLng is a valid number
  if (isNaN(latLng)) {
    return 'Invalid lat/lng';
  }

  // Format the lat/lng
  return latLng.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 4,
  });
};

export const formatDistance = (distance: number | undefined): string => {
  if (distance === undefined) {
    return '';
  }

  // Convert distance to a number if it's a string
  distance = Number(distance);

  // Check if distance is a valid number
  if (isNaN(distance)) {
    return 'Invalid distance';
  }

  // Convert to kilometers if distance is larger than 1000 meters
  if (distance >= 1000) {
    const kmDistance = (distance / 1000).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return kmDistance + ' km';
  }

  // Format the distance in meters
  const mDistance = distance.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return mDistance + ' m';
};

export function getThumbnailInfo(media: MediaValue): Pick<MediaValue, 'path' | 'url'> {
  const { path: originalPath, url: originalUrl } = media;
  // Extract components from the original path
  const pathParts = originalPath.split('/');
  const projectId = pathParts[1];
  const formId = pathParts[3];
  const responseId = pathParts[4];
  const fieldId = pathParts[5];
  const filename = pathParts[6];

  // eslint-disable-next-line prefer-const
  let [filenamewithoutext, ext] = filename.split('.');

  if (media.type === 'video') {
    ext = 'png';
  }

  // Construct the thumbnail path
  const thumbnailPath = `projects/${projectId}/forms/${formId}/${responseId}/${fieldId}/thumbs/${filenamewithoutext}_200x200${
    ext?.length ? `.${ext}` : ''
  }`;

  // Extract the base URL and token from the original URL
  const urlParts = originalUrl.split('?');
  const baseUrl = urlParts[0].substring(0, urlParts[0].lastIndexOf('/'));
  const token = urlParts[1];

  // Construct the thumbnail URL
  const thumbnailUrl = `${baseUrl}/${encodeURIComponent(thumbnailPath)}?${token}`;

  return {
    path: thumbnailPath,
    url: thumbnailUrl,
  };
}
