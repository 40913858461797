import { FormControl, FormGroup } from '@angular/forms';

export { calculateFieldVisibility } from './lib/calculate-field-visibility';
export * from './lib/date-utils';
export * from './lib/firestore-utils';
export * from './lib/form-utils';
export * from './lib/ng-utils';
export * from './lib/object-util';
export * from './lib/observable-utils';
export * from './lib/response-utils';

export const snakeCase = (str: string): string => {
  const words = str.split(/[\W_]+/);
  const snakeCaseWords = words.map((word) => word.toLowerCase());
  return snakeCaseWords.join('_');
};

export type UnionToIntersection<U> = (U extends unknown ? (k: U) => void : never) extends (k: infer I) => void
  ? I
  : never;

export type ModelFormGroup<T> = FormGroup<{
  [K in keyof T]: FormControl<T[K]>;
}>;

export function stringToHash(string: string): string {
  let hash = 0;

  if (string.length === 0) return hash.toString();

  for (let i = 0; i < string.length; i++) {
    const char = string.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }

  return hash.toString();
}
