import { Component } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { AngularFirePerformance } from '@angular/fire/compat/performance';
import { AngularFireRemoteConfig } from '@angular/fire/compat/remote-config';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { BaseAppComponent } from '@dataformz/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
  imports: [RouterModule],
})
export class AppComponent extends BaseAppComponent {
  constructor(
    _iconRegistry: MatIconRegistry,
    _domSanitizer: DomSanitizer,
    translate: TranslateService,
    router: Router,
    activatedRoute: ActivatedRoute,
    titleService: Title,
    analytics: AngularFireAnalytics,
    performance: AngularFirePerformance,
    remoteConfig: AngularFireRemoteConfig
  ) {
    super(translate, _iconRegistry, _domSanitizer, router, activatedRoute, titleService, analytics, performance);

    remoteConfig.fetchAndActivate().then();
  }
}
