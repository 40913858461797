import { ResponseAggregationPerUser } from './aggregations';
import { Doc } from './doc.interface';
import { PlanOptions } from './plan.interface';
import { Role, UserType } from './user.interface';
// import { Role } from './role';

export interface Project extends Doc {
  name?: string;
  uniqueName?: string;
  description?: string;
  users?: {
    [userId: string]: UserProject;
  };
  subscription?: ProjectSubscription;
  stripeCustomerId?: string;
}

export const featuresName = {
  forms: 'forms',
  data: 'data',
  users: 'users',
};

export interface UserProject {
  id: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  pictureUrl?: string;
  email?: string;
  isMember: boolean;
  isOwner: boolean;
  role: Role;
  type: UserType;
  restrictedAccess?: boolean;
  scopes: string[];
}

export interface ProjectSubscription {
  planId?: string;
  planName?: string;
  startAt?: Date | any;
  endAt?: Date | any;
  features?: {
    [name: string]: PlanOptions;
  };
  autoRenewActive?: boolean;
  stripeSubscriptionId?: string;
}

export interface ProjectAggregation {
  id: string;
  formsCount: number;
  responsesCount: number;
  collectorsCount: number;
  responsesPerUser?: ResponseAggregationPerUser;
}

export type ProjectWithAggregation = Project & ProjectAggregation;
