import { Role, User, UserType } from './user.interface';

export interface Invitation {
  id: string;
  email?: string;
  phoneNumber?: string;
  inviter?: User;
  active?: boolean;
  status?: StatusInvitation;
  projectName?: string;
  projectId?: string;
  role: Role;
  type: UserType;
  restrictedAccess?: boolean;
  scopes: string[];
}

export enum StatusInvitation {
  pending = 'pending',
  accepted = 'accepted',
  revoked = 'revoked',
}
