import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService } from '../services/auth.service';

export const authGuard: CanActivateFn = async (route, state) => {
  const authService = inject(AuthService);
  const isAuthenticated = await authService.isAuthenticated();

  if (!isAuthenticated) {
    authService.login(state.url);
    return false;
  } else {
    return true;
  }
};
