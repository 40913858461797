export interface Plan {
  id?: string;
  isDefault?: boolean;
  name?: string;
  price?: number;
  features?: {
    [name: string]: PlanOptions;
  };
}

export interface PlanOptions {
  limit?: number;
}

export const trialPlan = {
  id: 'trial',
  default: true,
  name: 'Trial',
  price: 0,
  features: {
    data: {
      limit: 1000,
    },
  },
};
