export const PermissionsNames = {
  // Project
  CREATE_PROJECT: 'createProject,',
  DELETE_PROJECT: 'deleteProject',
  EDIT_PROJECT: 'editproject',
  MANAGE_USERS: 'manageUsers',
  MANAGE_ROLES: 'manageRoles',

  // FORM
  CREATE_FORM: 'createForm',
  DELETE_FORM: 'deleteForm',
  EDIT_FORM: 'editForm',
  EDIT_FORM_ADVANCED: 'editFormAdvanced',

  // RESPONSE
  SUBMIT_RESPONSE: 'submitResponse',
  DELETE_RESPONSE: 'deleteResponse',
  EDIT_RESPONSE: 'editResponse',
  EXPORT_DATA: 'exportData',

  // // FIELD
  // ADD_FIELD: 'addField',
  // DELETE_FIELD: 'deleteField',
  // EDIT_FIELD: 'editField',
  // CHANGE_ORDER_FIELD: 'changeOrderField'
};

const HOST_PERMISSIONS: string[] = Object.values(PermissionsNames);

const ADMIN_PERMISSIONS: string[] = Object.values(PermissionsNames).filter(
  (permission) => permission !== PermissionsNames.EDIT_FORM_ADVANCED
);

const MANAGER_PERMISSIONS: string[] = [
  PermissionsNames.SUBMIT_RESPONSE,
  PermissionsNames.DELETE_RESPONSE,
  PermissionsNames.EDIT_RESPONSE,
];

const COLLECTOR_PERMISSIONS = [PermissionsNames.SUBMIT_RESPONSE];

export const ROLE_PERMISSIONS = {
  host: HOST_PERMISSIONS,
  admin: ADMIN_PERMISSIONS,
  manager: MANAGER_PERMISSIONS,
  collector: COLLECTOR_PERMISSIONS,
};
