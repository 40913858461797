import {
  DateValue,
  Field,
  FieldOption,
  FieldType,
  FieldValue,
  FormLinkValue,
  Response,
  Rule,
  RuleOperator,
  TimeValue,
} from '@dataformz/models';
import { compareFieldOption, convertDateValueToDate, convertTimeValueToTime } from './response-utils';

export function calculateFieldVisibility(field: Field, model: Response): boolean {
  if (field) {
    const condition = field.visibilityRules?.condition;
    const visibilityRules: Rule[] = field.visibilityRules?.rules as Rule[];
    const hasVisibilityRules: boolean = field.hasVisibilityRules ?? false;
    let isVisible = true;
    if (hasVisibilityRules && visibilityRules && visibilityRules.length > 0) {
      for (let i = 0; i < visibilityRules.length; i++) {
        const rule = visibilityRules[i];
        if (i === 0) {
          isVisible = evaluateRule(rule, model);
        } else {
          if (condition === 'AND') {
            isVisible = isVisible && evaluateRule(rule, model);
          } else {
            isVisible = isVisible || evaluateRule(rule, model);
          }
        }
      }
      return field.hidden || !isVisible;
    } else {
      return field.hidden ?? false;
    }
  }
  return false;
}

// ['eq','neq','gt','lt','gte','lte','contains','empty','notEmpty', 'startsWith',  'endsWith']
const evaluateRule = (rule: Rule, model: Response): boolean => {
  const fieldValue = rule.field?.uid ? (model?.[rule.field?.uid] as FieldValue) : undefined;
  const ruleValue = rule.value!;

  switch (rule.field.type) {
    case FieldType.text:
    case FieldType.paragraph:
      return evaluateText(rule.operator, fieldValue as string | undefined, ruleValue as string);
    case FieldType.choice:
      return evaluateChoice(rule.operator, fieldValue, ruleValue);
    case FieldType.date:
    case FieldType.time: {
      let fieldDate: Date | undefined = undefined;
      let ruleDate: Date;
      if (rule.field.type === FieldType.date) {
        fieldDate = fieldValue ? convertDateValueToDate(fieldValue as DateValue) : undefined;
        ruleDate = convertDateValueToDate(ruleValue as DateValue);
      } else {
        fieldDate = fieldValue ? convertTimeValueToTime(fieldValue as TimeValue) : undefined;
        ruleDate = convertTimeValueToTime(ruleValue as TimeValue);
      }
      return evaluateDate(rule.operator, fieldDate, ruleDate);
    }

    case FieldType.multipleChoice:
      return evaluateMultipleChoice(rule.operator, fieldValue as FieldOption[] | undefined, ruleValue as FieldOption);
    case FieldType.number:
    case FieldType.scale:
    case FieldType.rating:
      return evaluateNumber(rule.operator, fieldValue as number | undefined, ruleValue as number);
    case FieldType.formLink:
      return evaluteFormLink(rule.operator, fieldValue as FormLinkValue | undefined, ruleValue as FormLinkValue);
    case FieldType.yesNo:
      return evaluateBoolean(rule.operator, fieldValue as boolean | undefined, ruleValue as boolean);
    default:
      return false;
  }
};

const evaluateText = (operator: RuleOperator, fieldValue: string | undefined, ruleValue: string): boolean => {
  switch (operator) {
    case 'eq':
      return fieldValue === ruleValue;
    case 'neq':
      return fieldValue !== ruleValue;
    case 'contains':
      return !!fieldValue && fieldValue.includes(ruleValue);
    case 'notContains':
      return !!fieldValue && !fieldValue.includes(ruleValue);
    case 'empty':
      return !fieldValue;
    case 'notEmpty':
      return !!fieldValue;
    case 'startsWith':
      return !!fieldValue && fieldValue.startsWith(ruleValue);
    case 'endsWith':
      return !!fieldValue && fieldValue.endsWith(ruleValue);
    default:
      return false;
  }
};

const evaluateBoolean = (operator: RuleOperator, fieldValue: boolean | undefined, ruleValue: boolean): boolean => {
  switch (operator) {
    case 'eq':
      return fieldValue === ruleValue;
    case 'neq':
      return fieldValue !== ruleValue;
    default:
      return false;
  }
};

const evaluateNumber = (operator: RuleOperator, fieldValue: number | undefined, ruleValue: number): boolean => {
  switch (operator) {
    case 'eq':
      return fieldValue === ruleValue;
    case 'neq':
      return fieldValue !== ruleValue;
    case 'gt':
      return !!fieldValue && fieldValue > ruleValue;
    case 'lt':
      return !!fieldValue && fieldValue < ruleValue;
    case 'gte':
      return !!fieldValue && fieldValue >= ruleValue;
    case 'lte':
      return !!fieldValue && fieldValue <= ruleValue;
    case 'empty':
      return !fieldValue;
    case 'notEmpty':
      return !!fieldValue;
    default:
      return false;
  }
};

const evaluateDate = (operator: RuleOperator, fieldValue: Date | undefined, ruleValue: Date): boolean => {
  switch (operator) {
    case 'eq':
      return fieldValue?.getTime() === ruleValue.getTime();
    case 'neq':
      return fieldValue?.getTime() !== ruleValue.getTime();
    case 'gt':
      return !!fieldValue && fieldValue.getTime() > ruleValue.getTime();
    case 'lt':
      return !!fieldValue && fieldValue.getTime() < ruleValue.getTime();
    case 'gte':
      return !!fieldValue && fieldValue.getTime() >= ruleValue.getTime();
    case 'lte':
      return !!fieldValue && fieldValue.getTime() <= ruleValue.getTime();
    case 'empty':
      return !fieldValue;
    case 'notEmpty':
      return !!fieldValue;
    default:
      return false;
  }
};

const evaluateChoice = (
  operator: RuleOperator,
  fieldValue: FieldValue | undefined | null,
  ruleValue: FieldValue | undefined
): boolean => {
  const choice = fieldValue as FieldOption | undefined;
  const ruleChoice = ruleValue as FieldOption | undefined;
  switch (operator) {
    case 'eq':
      return !!choice && !!ruleChoice && compareFieldOption(choice, ruleChoice);
    case 'neq':
      return !choice || !ruleChoice || !compareFieldOption(choice, ruleChoice);
    case 'empty':
      return !fieldValue;
    case 'notEmpty':
      return !!fieldValue;
    default:
      return false;
  }
};

const evaluateMultipleChoice = (
  operator: RuleOperator,
  fieldValue: FieldOption[] | undefined,
  ruleValue: FieldOption
): boolean => {
  switch (operator) {
    case 'contains':
      return !!fieldValue && fieldValue.some((option) => compareFieldOption(option, ruleValue));
    case 'notContains':
      return !!fieldValue && !fieldValue.some((option) => compareFieldOption(option, ruleValue));
    case 'empty':
      return !fieldValue || fieldValue.length === 0;
    case 'notEmpty':
      return !!fieldValue && fieldValue.length > 0;
    default:
      return false;
  }
};

const evaluteFormLink = (
  operator: RuleOperator,
  fieldValue: FormLinkValue | undefined,
  ruleValue: FormLinkValue
): boolean => {
  switch (operator) {
    case 'eq':
      return !!fieldValue && fieldValue.id === ruleValue.id;
    case 'neq':
      return !fieldValue || fieldValue.id !== ruleValue.id;
    case 'empty':
      return !fieldValue;
    case 'notEmpty':
      return !!fieldValue;
    default:
      return false;
  }
};
