export const isNil = (value: any): value is null | undefined => {
  return value === null || typeof value === 'undefined';
};

export const isObject = (value: any): boolean => {
  return value && value.constructor === Object;
};

export const isBlank = (value: any): boolean => {
  return isNil(value) || (isObject(value) && Object.keys(value).length === 0) || value.toString().trim() === '';
};

export const isPresent = (value: any): boolean => {
  return !isBlank(value);
};

export function omitProperties<T extends object, K extends keyof T>(obj: T, ...keys: K[]): Omit<T, K> {
  const omittedKeysSet: Set<K> = new Set(keys);
  const rest: any = {};

  for (const key in obj) {
    if (!omittedKeysSet.has(key as unknown as K)) {
      rest[key] = obj[key];
    }
  }

  return rest as Omit<T, K>;
}

export function pickProperties<T extends object, K extends keyof T>(obj: T, ...keys: K[]): Pick<T, K> {
  const picked: Partial<Pick<T, K>> = {};
  for (const key of keys) {
    picked[key] = obj[key];
  }
  return picked as Pick<T, K>;
}
