import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

export const formatDateFromNow = (date: Date | null) => {
  if (!date) return '';
  return dayjs(date).fromNow();
};
