import { Routes } from '@angular/router';
import { authGuard, projectGuard } from '@dataformz/core';

export const routes: Routes = [
  // {
  //   path: 'profile',
  //   loadChildren: () => import('libs/user-profile/src/lib/user-profile.module').then((m) => m.UserProfileModule),
  // },
  {
    path: '',
    canActivate: [authGuard],
    canActivateChild: [authGuard],
    loadComponent: () => import('./main/main.component').then((m) => m.MainComponent),

    children: [
      {
        path: '',
        loadComponent: () => import('./projects/projects.component').then((m) => m.ProjectsComponent),
        pathMatch: 'full',
      },
      {
        path: 'project/:projectId',
        loadChildren: () => import('./project/routes').then((m) => m.routes),
      },

      {
        path: 'form/:projectId/:formId',
        canActivate: [projectGuard],
        loadChildren: () => import('./form/routes').then((m) => m.routes),
      },
      {
        path: 'profile',
        loadComponent: () => import('@dataformz/ui/profiles').then((m) => m.ProfilePageComponent),
        pathMatch: 'full',
      },
    ],
  },
];
