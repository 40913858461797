import { Inject, Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AuthService as Auth0Service, User as Auth0User } from '@auth0/auth0-angular';
import { APP_CONFIG } from '@dataformz/environments';
import { CollectionNames, CreateCustomTokenRequest, CreateUserRequest, User } from '@dataformz/models';
import { isNotNullOrUndefined } from '@dataformz/utils';
import * as Sentry from '@sentry/browser';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable, firstValueFrom } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { BaseService } from './base.service';

@Injectable({ providedIn: 'root' })
export class AuthService extends BaseService {
  constructor(
    afs: AngularFirestore,
    afAuth: AngularFireAuth,
    private aFunc: AngularFireFunctions,
    private permissionsService: NgxPermissionsService,
    private analytics: AngularFireAnalytics,
    private auth0Service: Auth0Service,
    @Inject(APP_CONFIG) private appconfig: any
  ) {
    super(afAuth, afs);

    this.afAuth.authState.subscribe((user) => {
      if (user) {
        Sentry.setUser({
          email: user.email || undefined,
          fullName: user.displayName,
          id: user.uid,
        });

        this.analytics.setUserId(user.uid);
        this.analytics.setUserProperties({ user_type: 'user' });

        // set TawkTo user
        if (this.appconfig.tawktoPropertyId) {
          // @ts-ignore
          window.Tawk_API?.setAttributes({
            name: user.displayName,
            email: user.email,
            hash: user.uid,
          });
        }
      } else {
        Sentry.setUser(null);
      }
    });
  }
  collectionName = () => 'Auth';

  async login(redirectPath: string = '/') {
    await this.auth0Service.loginWithRedirect({
      appState: { target: redirectPath },
    });
  }

  async loginToFirebase(user: Auth0User) {
    try {
      const idTokenClaims = await firstValueFrom(this.auth0Service.idTokenClaims$);

      const createCustomTokenFn = this.aFunc.httpsCallable<CreateCustomTokenRequest>('onCall_createCustomToken');
      const firebaseToken = await firstValueFrom(
        createCustomTokenFn({ idToken: idTokenClaims?.__raw!, uid: user.sub! })
      );

      await this.afAuth.signInWithCustomToken(firebaseToken);
    } catch (err) {
      console.error(err);
    }
  }
  async logout() {
    this.permissionsService.flushPermissions();
    await this.afAuth.signOut();
    // Call method to log out
    this.auth0Service.logout({
      logoutParams: {
        returnTo: `${window.location.origin}`,
      },
    });
  }

  async isAuthenticated(): Promise<boolean> {
    const isAuth0Authenticated = await firstValueFrom(this.auth0Service.isAuthenticated$);
    return isAuth0Authenticated;
  }

  async updateCurrentUser(user: Partial<User>) {
    if (this.user) {
      await this.afs.doc(`${CollectionNames.users}/${this.user.id}`).update(user);
    }
  }

  async createUserifNotExists(userId: string, user: Partial<User>) {
    const ref = `${CollectionNames.users}/${userId}`;
    const doc = await firstValueFrom(this.doc(ref).snapshotChanges().pipe(first()));
    if (!doc?.payload.exists) this.set(ref, user);
  }

  getUserById(userId: string): Observable<User | null> {
    return this.doc$<User>(`${CollectionNames.users}/${userId}`).pipe(map((user) => ({ ...user, id: userId } as User)));
  }

  get currentUser$(): Observable<User | null> {
    return this.afAuth.authState.pipe(
      isNotNullOrUndefined(),
      map((user) => user.uid),
      switchMap((userUid) => this.getUserById(userUid))
    );
  }

  createUser(user: User, password: string) {
    if (!user.type) throw new Error('Type cannot be empty');
    const createUserFn = this.aFunc.httpsCallable('auth0-createUser');
    return createUserFn({
      user: user,
      password: password,
    } as CreateUserRequest).toPromise();
  }

  async deleteCollectorAccount() {
    const user = await this.afAuth.currentUser;
    await user?.delete();
  }
}
