import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
  QueryFn,
  QueryGroupFn,
} from '@angular/fire/compat/firestore';
import { trace } from '@angular/fire/compat/performance';
import { Doc, SimpleUser } from '@dataformz/models';
import { isNotNullOrUndefined } from '@dataformz/utils';
import { DocumentReference } from '@firebase/firestore-types';
import * as firebase from 'firebase/compat/app';
import { Observable } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';

type CollectionPredicate<T> = string | AngularFirestoreCollection<T>;
type DocPredicate<T> = string | AngularFirestoreDocument<T>;

export abstract class BaseService {
  public user: SimpleUser | null = null;

  abstract collectionName(): string;

  constructor(protected afAuth: AngularFireAuth, protected afs: AngularFirestore) {
    this.afAuth.authState.pipe(filter((fUser) => !!fUser)).subscribe((user) => {
      if (user) {
        this.user = {
          id: user.uid,
          fullName: user.displayName ?? '',
          email: user.email ?? '',
          phoneNumber: user.phoneNumber ?? '',
        };
      } else this.user = null;
    });
  }

  protected get currentUserId$() {
    return this.afAuth.authState.pipe(
      isNotNullOrUndefined(),
      map((user: firebase.default.User | null) => user?.uid)
    );
  }

  /// **************
  /// Get a Reference
  /// **************
  protected col<T>(ref: CollectionPredicate<T>, queryFn?: QueryFn): AngularFirestoreCollection<T> {
    return typeof ref === 'string' ? this.afs.collection<T>(ref, queryFn) : ref;
  }

  protected colGroup<T>(ref: string, queryFn?: QueryGroupFn<T>) {
    return this.afs.collectionGroup<T>(ref, queryFn).valueChanges({ idField: 'id' });
  }

  protected doc<T>(ref: DocPredicate<T>): AngularFirestoreDocument<T> {
    return typeof ref === 'string' ? this.afs.doc<T>(ref) : ref;
  }

  /// **************
  /// Get Data
  /// **************
  protected doc$<T>(ref: DocPredicate<T>): Observable<T | null> {
    return this.doc(ref)
      .snapshotChanges()
      .pipe(
        catchError((err) => {
          console.error('ref', ref, err);
          throw err;
        }),
        trace(`get a ${this.collectionName()} doc`),
        map((doc) => {
          const data = doc.payload.data() as any;
          const id = doc.payload.id;
          if (data) {
            return {
              ...data,
              id,
            } as T;
          } else {
            return null;
          }
        })
      );
  }

  protected col$<T>(ref: CollectionPredicate<T>, queryFn?: QueryFn): Observable<T[]> {
    return this.col(ref, queryFn)
      .snapshotChanges()
      .pipe(
        catchError((err) => {
          console.error('ref', ref, err);
          throw err;
        }),
        trace(`get ${this.collectionName()} collection`),
        map((actions) => {
          return actions.map((a) => {
            const data = a.payload.doc.data() as any;
            const id = a.payload.doc.id;
            return {
              ...data,
              id,
            };
          });
        })
      );
  }

  /// **************
  /// Write Data
  /// **************
  /// Firebase Server Timestamp
  protected get timestamp() {
    return firebase.default.firestore.FieldValue.serverTimestamp();
  }

  protected get documentId() {
    return firebase.default.firestore.FieldPath.documentId();
  }

  protected set<T extends Doc>(ref: DocPredicate<T>, data: T) {
    return this.doc(ref).set({
      ...data,
      _createdAt: this.timestamp,
      _createdBy: this.user,
      _modifiedAt: this.timestamp,
      _modifiedBy: this.user,
      _isDeleted: false,
    });
  }

  protected update<T>(ref: DocPredicate<T>, data: Partial<T>) {
    return this.doc(ref).update({
      ...data,
      _modifiedAt: this.timestamp,
      _modifiedBy: this.user,
    });
  }

  protected delete<T>(ref: DocPredicate<T>) {
    return this.doc(ref).delete();
  }

  protected add<T>(ref: CollectionPredicate<T>, data: T): Promise<DocumentReference> {
    return this.col(ref).add({
      ...data,
      _createdAt: this.timestamp,
      _createdBy: this.user,
      _modifiedAt: this.timestamp,
      _modifiedBy: this.user,
      _isDeleted: false,
    }) as Promise<DocumentReference>;
  }

  protected geopoint(lat: number, lng: number) {
    return new firebase.default.firestore.GeoPoint(lat, lng);
  }

  protected sanitizeData(data: any): any {
    const sanitizedData: any = {};
    for (const key in data) {
      if (data[key] !== undefined) {
        sanitizedData[key] = data[key];
      }
    }
    return sanitizedData;
  }

  public createId() {
    return this.afs.createId();
  }
}
