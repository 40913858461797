import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { AngularFirePerformance } from '@angular/fire/compat/performance';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, mergeMap } from 'rxjs/operators';

export class BaseAppComponent {
  constructor(
    translate: TranslateService,
    _iconRegistry: MatIconRegistry,
    private _domSanitizer: DomSanitizer,
    router: Router,
    activatedRoute: ActivatedRoute,
    titleService: Title,
    analytics: AngularFireAnalytics,
    perfSvc: AngularFirePerformance
  ) {
    let lang = translate.getBrowserLang() ?? 'en';
    lang = /(en|fr)/gi.test(lang) ? lang : 'en';
    // Set fallback language
    translate.setDefaultLang('en');

    // Supported languages
    translate.use(lang);

    // Register svgs
    _iconRegistry.addSvgIcon(
      'dataformz',
      this.getSaniteIconUrl('/assets/logo_dark.svg')
    );
    _iconRegistry.addSvgIcon(
      'dataformz-icon',
      this.getSaniteIconUrl('/assets/icon.svg')
    );
    _iconRegistry.addSvgIcon(
      'area_map',
      this.getSaniteIconUrl('/assets/icons/area-map.svg')
    );
    _iconRegistry.addSvgIcon(
      'distance',
      this.getSaniteIconUrl('/assets/icons/distance.svg')
    );
    _iconRegistry.addSvgIcon(
      'external_link',
      this.getSaniteIconUrl('/assets/icons/external-link.svg')
    );

    router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => activatedRoute),
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe((event) => {
        const title = event['title'];
        titleService.setTitle(title ? `${title} - Dataformz` : 'Dataformz');
        if (title) {
          analytics.setCurrentScreen(title);
          analytics.logEvent('screen_view', { screen_name: title });
        }
        // console.log('Page Title', title);
      });

    //Workaround to get Firebase perf to log in prodmode
    perfSvc.dataCollectionEnabled.then((dcp) => {
      console.log('dcp enabled', dcp);
    });
  }

  getSaniteIconUrl(path: string) {
    return this._domSanitizer.bypassSecurityTrustResourceUrl(path);
  }
}
