import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, importProvidersFrom } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, Router, withComponentInputBinding } from '@angular/router';
import { provideAuth0 } from '@auth0/auth0-angular';
import { AppInitService, DFCoreModule, initializeAppFactory } from '@dataformz/core';
import { APP_CONFIG } from '@dataformz/environments';
import * as Sentry from '@sentry/angular';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { environment } from '../environments/environment';
import { routes } from './routes';

// Sentry
Sentry.init({
  dsn: environment.sentry,
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    Sentry.browserTracingIntegration(),
    // Registers the Replay integration,
    // which automatically captures Session Replays
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  release: environment.version,
});

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    { provide: APP_CONFIG, useValue: environment },
    importProvidersFrom(DFCoreModule.forRoot(environment)),
    provideAuth0({
      domain: environment.auth0.domain,
      clientId: environment.auth0.client_id,
      authorizationParams: {
        redirect_uri: `${window.location.origin}`,
      },
    }),
    provideRouter(routes, withComponentInputBinding()),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      multi: true,
      deps: [AppInitService],
    },
    importProvidersFrom(MonacoEditorModule.forRoot()),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
};
